import { SharedService } from 'src/app/_services/shared.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { get, set } from '../_services/storage.service';
import { UserModel } from '@models/UserModel';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    httpOptions = {};

    user: UserModel = null;
    _userDetails$: Subject<any> = new Subject();
    _defaultCompany$: Subject<any> = new Subject();

    constructor(
        private http: HttpClient,
        private sharedService: SharedService
    ) { }

    validate(value: string, mode: string) {
        const formData: FormData = new FormData();
        formData.append('value', value);
        formData.append('mode', mode);
        return this.http.post(environment.apiURL + 'authentication/validate', formData).pipe(
            map((response: any) => {
                const ret = response;
                if (ret) {
                    return ret;
                } else {
                    console.log('error');
                }
            })
        );
    }

    signin(value: string, code: string, mode: string) {
        const params: FormData = new FormData();
        params.append('value', value);
        params.append('code', code);
        params.append('mode', mode);
        return this.http.post(environment.apiURL + 'authentication/signin', params).pipe(
            map((response: any) => {
                const ret = response;
                if (ret) {
                    return ret;
                }
            })
        );
    }

    async isAuthenticated() {
        return !!await get('token');
    }


    async setDefaultCompany$(user: UserModel) {
        await set('user', JSON.stringify(user));
        this.user = user;
        this._defaultCompany$.next(user?.companies?.find(x => x.isDefault));
    }

    getUserCompany$() {
        if (this.user == null) {
            return this._defaultCompany$.asObservable();
        } else {
            this._defaultCompany$.next(this.user?.companies?.find(x => x.isDefault));
            return this._defaultCompany$.asObservable();
        }
    }

    async setUserDetails$(user) {
        await set('user', JSON.stringify(user));
        this.user = user;
        this._userDetails$.next(user);
    }

    getUserDetails$() {
        if (this.user == null) {
            return this._userDetails$.asObservable();
        } else {
            this._userDetails$.next(this.user);
            return this._userDetails$.asObservable();
        }
    }

    async getUser(): Promise<UserModel> {
        const userJSON = await get('user');

        if (userJSON) {
            return JSON.parse(userJSON) as UserModel;
        } else {
            return null;
        }
    }
}
