export const environment = {
  production: true,
  version: '1.00',
  build: '1088',
  mode: 'dev',
  apiURL: 'https://apidev.sommsays.co/',
  cdn: 'https://sommsays.azureedge.net/',
  url: 'https://dev.sommsays.co/',
  googleMapsAPIKey: 'AIzaSyBXn0cf9uDoWJI1YhKprMl59fOYGBbYOCs',
  firebaseConfig: {
    apiKey: 'AIzaSyAywQEnKYXTLAPS5FBn4o3HzJNYcAqxhpY',
    authDomain: 'sommsays-ce6ac.firebaseapp.com',
    databaseURL: 'https://sommsays-ce6ac.firebaseio.com',
    projectId: 'sommsays-ce6ac',
    storageBucket: 'sommsays-ce6ac.appspot.com',
    messagingSenderId: '591303308434',
    appId: '1:591303308434:web:237994059722df1596e24b',
    measurementId: 'G-B80B0HBC9L'
  }
};
