import { ParticipantModel } from './ParticipantModel';
import { RoundModel } from './RoundModel';

export class GameModel {
    id: string;
    userId: string;
    instanceId: string;
    rounds: Array<RoundModel>;
    participants: Array<ParticipantModel>;
}

