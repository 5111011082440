import { Company } from './CompanyModel';
import { UserModel } from './UserModel';
import { WineModel } from './WineModel';

export class SequenceModel {
    id: string;
    code: string;
    instanceId: string;
    userId: string;
    note: NoteModel;
    result: ResultModel;
    stats: StatModel;
    status: number;
    company: Company;
    isPrivate: boolean;
}

export class NoteModel {
    version: string;
    body: string;
    aroma: string;
    acidity: string;
    sugar: string;
    tannins: string;
    oak: string;
    flavors: Array<FlavorModel>;
    similar: Array<WineModel>;
    dissimilar: Array<WineModel>;
    user: UserModel;
    userId: string;
    wine: WineModel;
    description: string;
}

export class ResultModel {
    instanceId: string;
    body: string;
    aroma: string;
    acidity: string;
    sugar: string;
    tannins: string;
    oak: string;
    flavor: string;
    explicitPreference: number;
}

export class FlavorModel {
    value: string;
    selected: boolean;
}

export class StatModel {
    wineId: string;
    totalCount: number;
    body: Array<StatCountModel>;
    aroma: Array<StatCountModel>;
    acidity: Array<StatCountModel>;
    sugar: Array<StatCountModel>;
    tannins: Array<StatCountModel>;
    oak: Array<StatCountModel>;
    flavor: Array<StatCountModel>;
    explicitPreference: Array<StatCountModel>;
    change: ChangeModel;
}
export class StatCountModel {
    name: string;
    value: number;
    percentage: number;
    users: Array<UserModel>;
    totalUsers: number;
}

export class ChangeModel {
    type: string;
    value: number;
}