import { SharedService } from '../_services/shared.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { get } from '@services/storage.service';
import { AuthenticationService } from '@services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class OnboardedGuard implements CanActivate {
    user: any;

    constructor(
        private router: Router,
        private sharedService: SharedService,
        private authService: AuthenticationService
    ) { }

    async canActivate(): Promise<boolean> {

        this.user = await this.authService.getUser();

        if (!this.isOnboarded()) {
            this.router.navigate(['/update']);
            return false;
        }

        return true;
    }

    isOnboarded(): boolean {
        return this.user.firstName && this.user.lastName && this.user.username;
    }
}
