import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { AuthedGuard } from './_guards/authed.guard';
import { OnboardedGuard } from './_guards/onboarded.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./loader/loader.module').then(m => m.LoaderPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'game',
    loadChildren: () => import('./game/game.module').then(m => m.GamePageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'game/:code',
    loadChildren: () => import('./game/game.module').then(m => m.GamePageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'store',
    loadChildren: () => import('./store/store.module').then(m => m.StorePageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then(m => m.ProductPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'address',
    loadChildren: () => import('./address/address.module').then(m => m.AddressPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./confirmation/confirmation.module').then(m => m.ConfirmationPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'verify',
    loadChildren: () => import('./verify/verify.module').then(m => m.VerifyPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'play',
    loadChildren: () => import('./play/play.module').then(m => m.PlayPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'summary',
    loadChildren: () => import('./summary/summary.module').then(m => m.SummaryPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'how',
    loadChildren: () => import('./how/how.module').then(m => m.HowPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'scan',
    loadChildren: () => import('./scan/scan.module').then(m => m.ScanPageModule),
    canActivate: [AuthedGuard]
  },
  {
    path: 'scan/:code',
    loadChildren: () => import('./scan/scan.module').then(m => m.ScanPageModule),
    canActivate: [AuthedGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then(m => m.SupportPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'premium',
    loadChildren: () => import('./premium/premium.module').then(m => m.PremiumPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'update',
    loadChildren: () => import('./update/update.module').then(m => m.UpdatePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then(m => m.CartPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'lobby',
    loadChildren: () => import('./lobby/lobby.module').then(m => m.LobbyPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'setup',
    loadChildren: () => import('./setup/setup.module').then(m => m.SetupPageModule),
    canActivate: [AuthedGuard]
  },
  {
    path: 'setup/:code',
    loadChildren: () => import('./setup/setup.module').then(m => m.SetupPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then(m => m.IntroPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'poc',
    loadChildren: () => import('./poc/poc.module').then(m => m.PocPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyPageModule)
  },
  {
    path: 'authentication',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationPageModule)
  },
  {
    path: 'why',
    loadChildren: () => import('./why/why.module').then(m => m.WhyPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'notes',
    loadChildren: () => import('./notes/notes.module').then(m => m.NotesPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'notes/:action',
    loadChildren: () => import('./notes/notes.module').then(m => m.NotesPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'games',
    loadChildren: () => import('./games/games.module').then(m => m.GamesPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'eula',
    loadChildren: () => import('./eula/eula.module').then(m => m.EulaPageModule)
  },
  {
    path: 'flavorwave',
    loadChildren: () => import('./flavorwave/flavorwave.module').then(m => m.FlavorwavePageModule)
  },
  {
    path: 'wine/:wineId',
    loadChildren: () => import('./wine/wine.module').then(m => m.WinePageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'somm/:userId',
    loadChildren: () => import('./somm/somm.module').then(m => m.SommPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'company/:companyId',
    loadChildren: () => import('./company/company.module').then(m => m.CompanyPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'qr',
    loadChildren: () => import('./qr/qr.module').then(m => m.QrPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'render',
    loadChildren: () => import('./render/render.module').then(m => m.RenderPageModule),
    canActivate: [AuthGuard, OnboardedGuard]
  },
  {
    path: 'flavorwave2',
    loadChildren: () => import('./flavorwave2/flavorwave2.module').then(m => m.Flavorwave2PageModule)
  },
  {
    path: 'gameresults',
    loadChildren: () => import('./gameresults/gameresults.module').then(m => m.GameresultsPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)
  },  {
    path: 'faq-view',
    loadChildren: () => import('./faq-view/faq-view.module').then( m => m.FaqViewPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
