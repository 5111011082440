import { ParticipantModel } from './ParticipantModel';
import { UserModel } from './UserModel';

export class ChoiceModel {
    name: string;
    label: string;
    percentage: number;
    userSelected: boolean;
    sommSelected: boolean;
    participants: Array<UserModel>;
    participantsTotal: number;
}

