import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-pop',
  templateUrl: './pop.component.html',
  styleUrls: ['./pop.component.scss']
})
export class PopComponent implements OnInit {

  @Input() gameSequence;

  constructor(
    private popoverController: PopoverController,
  ) {}

  ngOnInit() {
  }

  playGame() {
    this.popoverController.dismiss({ response: 'play', game: this.gameSequence});
  }

  reviewGame() {
    this.popoverController.dismiss({ response: 'review', game: this.gameSequence });
  }

  shareGame() {
    this.popoverController.dismiss({ response: 'share', game: this.gameSequence });
  }
}
