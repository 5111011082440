import { ResultModel, SequenceModel } from './../_models/SequenceModel';
import { Injectable } from '@angular/core';
import { ChoiceModel } from '../_models/ChoiceModel';
import { GameModel } from '../_models/GameModel';
import { ParticipantModel } from '../_models/ParticipantModel';
import { ProductModel } from '../_models/ProductModel';
import { RoundModel } from '../_models/RoundModel';
import { UserModel } from '../_models/UserModel';

@Injectable()
export class GlobalService {
    public currentGame: GameModel;
    public currentResult: ResultModel;

    public generateCurrentGame(sequence: SequenceModel) {

        this.currentGame = new GameModel();
        this.currentGame.instanceId = sequence.instanceId;
        this.currentGame.rounds = new Array<RoundModel>();
        this.currentGame.participants = new Array<ParticipantModel>();

        this.currentResult = new ResultModel();
        this.currentResult = sequence.result;

        this.createOrUpdateChoices(sequence);
    }

    public createOrUpdateChoices(sequence: SequenceModel) {
        this.aroma(sequence);
        this.body(sequence);
        this.acidity(sequence);
        if (sequence.note.tannins) {
            this.tannins(sequence);
        } else {
            this.oaks(sequence);
        }

        this.sugar(sequence);
        this.flavor(sequence);
        this.rate(sequence);
    }

    private body(sequence: SequenceModel) {
        const round = new RoundModel();
        round.name = 'body';
        round.content = '';
        round.description = 'Start with what you see. To figure out the body, you want to look first.';
        round.image = '';
        round.showPercentages = true;
        round.question = 'Describe the Body';
        round.products = new Array<ProductModel>();
        round.choices = new Array<ChoiceModel>();

        const values: Array<string> = ['light', 'medium', 'full'];
        values.forEach(value => {
            const choice = new ChoiceModel();
            choice.label = this.titleCase(value);
            choice.name = value;
            choice.percentage = this.getPercentage(sequence, 'body', value);
            choice.participants = this.getParticipants(sequence, 'body', value);
            choice.participantsTotal = this.getParticipantsTotal(sequence, 'body', value);
            choice.sommSelected = sequence.note.body === value;
            if (sequence.result && sequence.result.body) {
                choice.userSelected = sequence.result.body === value;
                round.isClosed = true;
            } else {
                choice.userSelected = false;
            }
            round.choices.push(choice);
        });

        this.addOrReplaceRound(round);
    }

    private aroma(sequence: SequenceModel) {
        const round = new RoundModel();
        round.name = 'aroma';
        round.content = '';
        round.description = 'Take in the aromas and tell me what stands out stands out most to you?';
        round.image = '';
        round.showPercentages = true;
        round.question = 'Describe the Aroma';
        round.products = new Array<ProductModel>();
        round.choices = new Array<ChoiceModel>();

        const values: Array<string> = ['floral', 'fruity', 'herbal', 'spicy', 'smoky', 'earthy'];
        values.forEach(value => {
            const choice = new ChoiceModel();
            choice.label = this.titleCase(value);
            choice.name = value;
            choice.percentage = this.getPercentage(sequence, 'aroma', value);
            choice.participants = this.getParticipants(sequence, 'aroma', value);
            choice.participantsTotal = this.getParticipantsTotal(sequence, 'aroma', value);
            choice.sommSelected = sequence.note.aroma === value;
            if (sequence.result && sequence.result.aroma) {
                choice.userSelected = sequence.result.aroma === value;
                round.isClosed = true;
            } else {
                choice.userSelected = false;
            }
            round.choices.push(choice);
        });

        this.addOrReplaceRound(round);
    }

    private acidity(sequence: SequenceModel) {
        const round = new RoundModel();
        round.name = 'acidity';
        round.content = '';
        round.description = 'Correct! Sum dolor sit amet, consectete adipiscing elit. Nam ac tortor rhoncus.';
        round.image = '';
        round.showPercentages = true;
        round.question = 'Describe the Acidity';
        round.products = new Array<ProductModel>();
        round.choices = new Array<ChoiceModel>();

        const values: Array<string> = ['creamy', 'crisp', 'tart'];
        values.forEach(value => {
            const choice = new ChoiceModel();
            choice.label = this.titleCase(value);
            choice.name = value;
            choice.percentage = this.getPercentage(sequence, round.name, value);
            choice.participants = this.getParticipants(sequence, round.name, value);
            choice.participantsTotal = this.getParticipantsTotal(sequence, round.name, value);
            choice.sommSelected = sequence.note.acidity === value;
            if (sequence.result && sequence.result.acidity) {
                choice.userSelected = sequence.result.acidity === value;
                round.isClosed = true;
            } else {
                choice.userSelected = false;
            }
            round.choices.push(choice);
        });

        this.addOrReplaceRound(round);
    }

    private sugar(sequence: SequenceModel) {
        const round = new RoundModel();
        round.name = 'sugar';
        round.content = '';
        round.description = 'Lorem ipsum dolor sit amet, consectete adipiscing elit. Nam ac tortor rhoncus.';
        round.image = '';
        round.showPercentages = true;
        round.question = 'Describe the Sugar';
        round.products = new Array<ProductModel>();
        round.choices = new Array<ChoiceModel>();

        const values: Array<string> = ['dry', 'med dry', 'med sweet', 'sweet'];
        values.forEach(value => {
            const choice = new ChoiceModel();
            choice.label = this.titleCase(value);
            choice.name = value;
            choice.percentage = this.getPercentage(sequence, round.name, value);
            choice.participants = this.getParticipants(sequence, round.name, value);
            choice.participantsTotal = this.getParticipantsTotal(sequence, round.name, value);
            choice.sommSelected = sequence.note.sugar.toLowerCase() === value.toLowerCase();
            if (sequence.result && sequence.result.sugar) {
                choice.userSelected = sequence.result.sugar === value;
                round.isClosed = true;
            } else {
                choice.userSelected = false;
            }
            round.choices.push(choice);
        });

        this.addOrReplaceRound(round);
    }

    private tannins(sequence: SequenceModel) {
        const round = new RoundModel();
        round.name = 'tannins';
        round.content = '';
        round.description = 'Lorem ipsum dolor sit amet, consectete adipiscing elit. Nam ac tortor rhoncus.';
        round.image = '';
        round.showPercentages = true;
        round.question = 'Describe the Tannins';
        round.products = new Array<ProductModel>();
        round.choices = new Array<ChoiceModel>();

        const values: Array<string> = ['silky', 'fine grain', 'grainy'];
        values.forEach(value => {
            const choice = new ChoiceModel();
            choice.label = this.titleCase(value);
            choice.name = value;
            choice.percentage = this.getPercentage(sequence, round.name, value);
            choice.participants = this.getParticipants(sequence, round.name, value);
            choice.participantsTotal = this.getParticipantsTotal(sequence, round.name, value);
            choice.sommSelected = sequence.note.tannins === value;
            if (sequence.result && sequence.result.tannins) {
                choice.userSelected = sequence.result.tannins === value;
                round.isClosed = true;
            } else {
                choice.userSelected = false;
            }
            round.choices.push(choice);
        });

        this.addOrReplaceRound(round);
    }

    private oaks(sequence: SequenceModel) {
        const round = new RoundModel();
        round.name = 'oak';
        round.content = '';
        round.description = 'Lorem ipsum dolor sit amet, consectete adipiscing elit. Nam ac tortor rhoncus.';
        round.image = '';
        round.showPercentages = true;
        round.question = 'Describe the Oak';
        round.products = new Array<ProductModel>();
        round.choices = new Array<ChoiceModel>();

        const values: Array<string> = ['none', 'light', 'moderate', 'heavy'];
        values.forEach(value => {
            const choice = new ChoiceModel();
            choice.label = this.titleCase(value);
            choice.name = value;
            choice.percentage = this.getPercentage(sequence, round.name, value);
            choice.participants = this.getParticipants(sequence, round.name, value);
            choice.participantsTotal = this.getParticipantsTotal(sequence, round.name, value);
            choice.sommSelected = sequence.note.oak === value;
            if (sequence.result && sequence.result.oak) {
                choice.userSelected = sequence.result.oak === value;
                round.isClosed = true;
            } else {
                choice.userSelected = false;
            }
            round.choices.push(choice);
        });

        this.addOrReplaceRound(round);
    }

    private flavor(sequence: SequenceModel) {
        const round = new RoundModel();
        round.name = 'flavor';
        round.content = '';
        round.description = 'Lorem ipsum dolor sit amet, consectete adipiscing elit. Nam ac tortor rhoncus.';
        round.image = '';
        round.showPercentages = true;
        round.question = 'Describe the Flavor';
        round.products = new Array<ProductModel>();
        round.choices = new Array<ChoiceModel>();

        for (const flavor of sequence.note.flavors) {
            const choice = new ChoiceModel();
            choice.label = this.titleCase(flavor.value);
            choice.name = flavor.value;
            choice.percentage = this.getPercentage(sequence, 'flavor', flavor.value);
            choice.participants = this.getParticipants(sequence, 'flavor', flavor.value);
            choice.participantsTotal = this.getParticipantsTotal(sequence, 'flavor', flavor.value);
            choice.sommSelected = flavor.selected;
            if (sequence.result && sequence.result.flavor) {
                choice.userSelected = sequence.result.flavor === flavor.value;
                round.isClosed = true;
            } else {
                choice.userSelected = false;
            }
            round.choices.push(choice);
        }

        this.addOrReplaceRound(round);
    }

    private rate(sequence: SequenceModel) {
        const round = new RoundModel();
        round.name = 'rate';
        round.content = '';
        round.description = 'Here’s the big question. Tell me how well this wine preformed FOR you.';
        round.image = '';
        round.showPercentages = true;
        round.question = 'Rate this wine';
        round.products = new Array<ProductModel>();
        round.choices = new Array<ChoiceModel>();

        const name = 'explicitPreference';
        const values: Array<string> = ['100', '75', '50', '25'];
        const labels: Array<string> = ['Top 10%', 'Top 25%', 'Average', 'Not for me'];

        for (let i = 0; i < values.length; i++) {
            const choice = new ChoiceModel();
            choice.label = labels[i];
            choice.name = values[i];
            choice.percentage = this.getPercentage(sequence, name, values[i]);
            choice.participants = this.getParticipants(sequence, name, values[i]);
            choice.participantsTotal = this.getParticipantsTotal(sequence, name, values[i]);
            choice.sommSelected = false;
            if (sequence.result && sequence.result.explicitPreference) {
                choice.userSelected = sequence.result.explicitPreference === Number(values[i]);
                round.isClosed = true;
            } else {
                choice.userSelected = false;
            }
            round.choices.push(choice);
        }

        this.addOrReplaceRound(round);
    }

    private addOrReplaceRound(round: RoundModel) {
        const index = this.currentGame.rounds.findIndex(x => x.name === round.name);

        if (index > -1) {
            this.currentGame.rounds[index] = round;
        } else {
            this.currentGame.rounds.push(round);
        }
    }

    private getPercentage(sequence: SequenceModel, type: string, name: string): number {
        let ret = 0;

        switch (type) {
            case 'body':
                if (sequence.stats.body.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.body.filter(s => s.name === name)[0].percentage;
                }
                break;
            case 'aroma':
                if (sequence.stats.aroma.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.aroma.filter(s => s.name === name)[0].percentage;
                }
                break;
            case 'acidity':
                if (sequence.stats.acidity.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.acidity.filter(s => s.name === name)[0].percentage;
                }
                break;
            case 'sugar':
                if (sequence.stats.sugar.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.sugar.filter(s => s.name === name)[0].percentage;
                }
                break;
            case 'tannins':
                if (sequence.stats.tannins.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.tannins.filter(s => s.name === name)[0].percentage;
                }
                break;
            case 'oak':
                if (sequence.stats.oak.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.oak.filter(s => s.name === name)[0].percentage;
                }
                break;
            case 'flavor':
                if (sequence.stats.flavor.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.flavor.filter(s => s.name === name)[0].percentage;
                }
                break;
            case 'explicitPreference':
                if (sequence.stats.explicitPreference.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.explicitPreference.filter(s => s.name === name)[0].percentage;
                }
                break;
        }


        return ret;
    }

    private getParticipants(sequence: SequenceModel, type: string, name: string): Array<UserModel> {

        let ret: Array<UserModel> = new Array<UserModel>();

        switch (type) {
            case 'body':
                if (sequence.stats.body.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.body.filter(s => s.name === name)[0].users;
                }
                break;
            case 'aroma':
                if (sequence.stats.aroma.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.aroma.filter(s => s.name === name)[0].users;
                }
                break;
            case 'acidity':
                if (sequence.stats.acidity.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.acidity.filter(s => s.name === name)[0].users;
                }
                break;
            case 'sugar':
                if (sequence.stats.sugar.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.sugar.filter(s => s.name === name)[0].users;
                }
                break;
            case 'tannins':
                if (sequence.stats.tannins.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.tannins.filter(s => s.name === name)[0].users;
                }
                break;
            case 'oak':
                if (sequence.stats.oak.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.oak.filter(s => s.name === name)[0].users;
                }
                break;
            case 'flavor':
                if (sequence.stats.flavor.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.flavor.filter(s => s.name === name)[0].users;
                }
                break;
            case 'explicitPreference':
                if (sequence.stats.explicitPreference.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.explicitPreference.filter(s => s.name === name)[0].users;
                }
                break;
        }


        return ret;
    }

    private getParticipantsTotal(sequence: SequenceModel, type: string, name: string): number {

        let ret = 0;

        switch (type) {
            case 'body':
                if (sequence.stats.body.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.body.filter(s => s.name === name)[0].totalUsers;
                }
                break;
            case 'aroma':
                if (sequence.stats.aroma.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.aroma.filter(s => s.name === name)[0].totalUsers;
                }
                break;
            case 'acidity':
                if (sequence.stats.acidity.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.acidity.filter(s => s.name === name)[0].totalUsers;
                }
                break;
            case 'sugar':
                if (sequence.stats.sugar.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.sugar.filter(s => s.name === name)[0].totalUsers;
                }
                break;
            case 'tannins':
                console.log(sequence.stats);
                if (sequence.stats.tannins.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.tannins.filter(s => s.name === name)[0].totalUsers;
                }
                break;
            case 'oak':
                if (sequence.stats.oak.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.oak.filter(s => s.name === name)[0].totalUsers;
                }
                break;
            case 'flavor':
                if (sequence.stats.flavor.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.flavor.filter(s => s.name === name)[0].totalUsers;
                }
                break;
            case 'explicitPreference':
                if (sequence.stats.explicitPreference.filter(s => s.name === name).length > 0) {
                    ret = sequence.stats.explicitPreference.filter(s => s.name === name)[0].totalUsers;
                }
                break;
        }


        return ret;
    }

    private titleCase(str) {
        str = str.toLowerCase().split(' ');
        for (let i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    }
}
