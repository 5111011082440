import { ChoiceModel } from './ChoiceModel';
import { ProductModel } from './ProductModel';

export class RoundModel {
    index: number;
    image: string;
    name: string;
    question: string;
    content: string;
    description: string;
    isClosed: boolean;
    isCorrect: boolean;
    showPercentages: boolean;
    choices: Array<ChoiceModel>;
    products: Array<ProductModel>;
}

