import { SharedService } from './../_services/shared.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { get } from '@services/storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private sharedService: SharedService
    ) { }

    async canActivate(): Promise<boolean> {

        if (this.sharedService.token) {
            return true;
        }

        const authToken = await get('token');
        if (authToken) {
            this.sharedService.token = authToken;
            return true;
        }

        this.router.navigate(['/authentication']);
        return false;
    }
}
