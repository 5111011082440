import { SequenceModel } from "./../_models/SequenceModel";
import { Injectable } from "@angular/core";

@Injectable()
export class SharedService {
  public token: string;
  public code: string;
  public isReferralQR: boolean = false;
  public sequence: SequenceModel;

  public static updateUrlParameter(uri, key, value) {
    // remove the hash part before operating on the uri
    var i = uri.indexOf("#");
    var hash = i === -1 ? "" : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);

    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf("?") !== -1 ? "&" : "?";

    if (value === null) {
      uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), "");
      if (uri.slice(-1) === "?") {
        uri = uri.slice(0, -1);
      }
      // replace first occurrence of & by ? if no ? is present
      if (uri.indexOf("?") === -1) uri = uri.replace(/&/, "?");
    } else if (uri.match(re)) {
      uri = uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
      uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
  }
}
