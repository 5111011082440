import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { SharedService } from 'src/app/_services/shared.service';
import { Observable } from 'rxjs';
import { get } from '@services/storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private sharedService: SharedService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({ setHeaders: { 'x-platform': 'pwa' } });

        if (this.sharedService.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.sharedService.token}`,
                    'x-platform': 'pwa'
                }
            });
        }

        return next.handle(request);
    }
}
