import { SharedService } from './../_services/shared.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { get } from '@services/storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthedGuard implements CanActivate {
    constructor(
        private sharedService: SharedService
    ) { }

    async canActivate(): Promise<boolean> {

        if (this.sharedService.token) {
            return true;
        }

        const authToken = await get('token');
        if (authToken) {
            this.sharedService.token = authToken;
            return true;
        }

        return true;
    }
}
